/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.css';  
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/*====================================
GENERAL
======================================*/
html, body {
  margin: 0 !important;
  height: 100%;
  min-width: 380px;
}
a {
  text-decoration: none !important;
  color: black;
}
a.disabled {
  pointer-events: none;
  cursor: not-allowed; 
  color: gray;
}
.border-none {
  border: none;
}
.bg-black {
  background: #1a1919;
}
.font-weight-medium {
  font-weight: 600;
}
.f42 {
  font-size: 42px;
}
.f40 {
  font-size: 40px;
}
.f24 {
  font-size: 24px;
}
.f18 {
  font-size: 18px;
}
.f16 {
  font-size: 16px;
}
.f14 {
  font-size: 14px;
}
.f12 {
  font-size: 12px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-84 {
  margin-top: 84px;
}
.mh-96 {
  min-height: calc(100vh - 150px);
  background: #fff;
}
.text-alert {
  color: red;
}
.primary-text {
  color: #ee2a24;
}
.text-gray {
  color: gray;
}
button:focus,
textarea:focus {
  outline: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.visible-hide {
  opacity: 0; 
  overflow: hidden; 
  visibility: hidden; 
  height: 0;
}
.full-width-field {
  width: 100%;
}
img {
  object-fit: cover;
}
.mw-200px {
  max-width: 200px;
}
.text-underline {
  text-decoration: underline !important;
}

.white-space {
  white-space: pre-line
}

/*====================================
NAVIGATION
======================================*/
.sidenav-box {
  margin-top: 70px;
  padding: 15px;
}
.content li a {
  font-size: 24px;
  color: #000;
}
.content li a:hover {
  color: #ee2a24;
  text-decoration: none;
}
.mat-drawer {
  min-width: 300px;
}
nav.navbar {
  background-color: transparent !important;
}
.page-content {
  margin-top: 80px;
}

/*====================================
GHOST ELEMENT
======================================*/
.ghost-ui .shimmer {
  height: 20px;
  margin: 10px 5px;
  animation-duration: 10s;
  animation-fill-mode: forwards; 
  animation-iteration-count: infinite;
  animation-name: ghostElementShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
}
@keyframes ghostElementShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0; 
  }
}

/*====================================
ORDER STYLE 
======================================*/
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before,
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 50px !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  width: calc(50% - 30px) !important;
}
.mat-step-icon {
  height: 50px !important;
  width: 50px !important;
}

/*====================================
USER DASHBOARD
======================================*/
.mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {
  padding: 0 4px !important;
}
.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  height: 36px !important;
}
.mat-list-base .mat-list-item .mat-list-text>*, .mat-list-base .mat-list-option .mat-list-text>* {
  font-weight: 600 !important;
}
/* sidebar active */
mat-list-option.mat-list-item.mat-list-option.mat-focus-indicator.mat-accent.active a {
  color: #ee2a24;
}
mat-list-option.mat-list-item.mat-list-option.mat-focus-indicator.mat-accent.active,
.mat-list-option:hover, .mat-list-option:focus, .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus, .mat-action-list .mat-list-item:hover, .mat-action-list .mat-list-item:focus {
  background: none;
}

/*====================================
BUTTONS
======================================*/
.btn-white-round {
  background: #fff;
  color: #292929;
  border-radius: 22px;
  box-shadow: 0 7px 10px rgb(220 220 220);
  max-width: 220px;
  width: 100%;
  font-weight: 600;
}
.btn-white-round:hover {
  background: #292929;
  color: #fff;
  box-shadow: 0 7px 10px rgb(220 220 220);
}

.btn-black-round,
.btn-redeem,
.btn-select {
  background: #292929;
  color: #fff;
  border-radius: 22px;
  box-shadow: 0 7px 10px rgb(150, 150, 150);
  max-width: 220px;
  width: 100%;
  font-weight: 600;
}
.btn-black-round:hover,
.btn-redeem:hover {
  background: #ee2a24;
  color: #fff;
  box-shadow: 0 7px 10px #EE2A2458;
}
.btn-primary-round {
  background: #ee2a24;
  border-radius: 22px;
  box-shadow: 0 7px 10px #EE2A2458;
  max-width: 220px;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
.btn-primary-round:hover {
  background: #292929;
  color: #fff;
  box-shadow: 0 7px 10px rgb(150, 150, 150);
}
.btn-login,
.btn-register,
.btn-forgot,
.btn-tac {
  background: #292929;
  color: #fff;
  box-shadow: 0 7px 10px rgb(150, 150, 150);
}
.btn-facebook {
  border: 2px solid #3B5998;
  color: #3B5998;
}
.btn-facebook,
.btn-login,
.btn-register,
.btn-forgot,
.btn-tac {
  max-width: 400px !important;
  width: 100% !important;
  font-weight: 600;
  line-height: 36px;
  font-size: 18px;
  border-radius: 32px;
}
.btn-payment:hover {
  background: #ee2a24;
  color: #fff;
}
.btn-payment {
  font-weight: 600;
}
.btn-tac {
  max-width: 200px !important;
  width: 100% !important;
  font-weight: 600;
  line-height: 36px;
  font-size: 18px;
  border-radius: 32px;
  font-weight: 600;
}
.btn-login:hover,
.btn-register:hover,
.btn-forgot:hover,
.btn-tac:hover {
  background: #ee2a24;
  color: #fff;
  box-shadow: 0 7px 10px #EE2A2458;
}
.btn-facebook:hover {
  box-shadow: 0 7px 10px #d9e5ff;
  background: #3B5998;
  color: #fff;
}
.btn-add-to-cart {
  background: #ee2a24;
  color: #fff;
  border-radius: 22px;
  box-shadow: 0 7px 10px #EE2A2458;
  max-width: 140px;
  width: 100%;
  font-weight: 600;
}
.btn-add-to-cart:hover {
  color: #fff;
  background: #292929;
  box-shadow: 0 7px 10px rgb(150, 150, 150);
}
.btn-select {
  margin-top: 0 !important;
  background-color: #292929 !important;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  border: none !important;
}
.btn-select.store-selected {
  background: #ee2a24 !important;
  box-shadow: 0 7px 10px #EE2A2458;
}
.btn-upload {
  position: absolute !important;
  bottom: 0;
  right: -10px;
}

/*====================================
BOX
======================================*/
.content-box {
  box-shadow: 0px 0px 25px #dfe3e6;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  position: relative;
}
.product .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  padding: 20px 15px;
  border-radius: 15px;
  background-color: #fff;
  height: 100%;
}
.box .product-img {
  text-align: center !important;
}
.membership-box {
  border-radius: 10px;
  box-shadow: 0 3px 6px #605E5EA5;
  max-width: 400px;
  width: 100%;
  padding: 40px;
  color: #fff;
  background: rgb(238,42,36);
  background: linear-gradient(147deg, rgba(238,42,36,1) 0%, rgba(238,42,36,1) 10%, rgba(210,28,22,1) 31%, rgba(195,20,15,1) 43%, rgba(238,42,36,1) 59%, rgba(195,20,15,1) 79%, rgba(195,20,15,1) 100%);
}

/*====================================
SQUARE PAYMENT FORM
======================================*/
.sq-input {
  border: 1px solid #E0E2E3;
  border-radius: 4px;
}
.sq-input--focus {
  box-shadow: 0px 0px 25px #dfe3e6;
}
.sq-input--error {
  border-color: red;
}
#sq-ccbox {
  /* border: 1px solid #dfe3e6; */
  padding: 24px 24px 0;
}
/*====================================
CART
======================================*/
.cart-section .item-name {
  font-weight: bold;
  font-size: 18px;
}
.cart-section .cart-img {
  max-width: 100px;
  width: 100%;
}
.cart-section .item-quantity {
  border: 1px solid #dee2e6;
  padding: 8px;
  width: 50px;
}
.cart-section .delete-icon {
  cursor: pointer;
}
.cart-section .cart-item {
  min-height: 160px;
  margin-bottom: 16px;
}
.align-items-bottom {
  align-items: flex-end;
}

@media(max-width: 1380px) and (min-width: 950px) {
  .cart-price-row {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left !important;
  }
  .cart-qty-row {
    flex: 0 0 50%;
    max-width: 50%;
  }
  /* .cart-section .cart-item {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 8px;
  } */
}

@media (max-width: 950px) and (min-width: 768px) {
  .cart-img-wrap {
    margin: auto 0;
  }
  .cart-desc-row {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cart-section .cart-item {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .cart-section .cart-item .form-row,
  .cart-section .cart-item .item-price {
    margin-bottom: 8px;
  }
  .cart-section .cart-item .description,
  .cart-section .cart-item .item-price {
    margin-top: 16px
  }
  .cart-section .cart-item {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 16px;
  }
  .detail-content {
    margin-top: 16px;
  }
}

@media (max-width: 645px) {
  .cart-price-row {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left !important;
  }
  .cart-qty-row>.d-flex {
    justify-content: flex-end !important;
  }
  .cart-qty-row {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cart-img-wrap {
    margin: auto 0;
  }
  .cart-section .cart-item .form-row,
  .cart-section .cart-item .item-price {
    margin-bottom: 8px;
  }
  .cart-section .cart-item .description,
  .cart-section .cart-item .item-price {
    margin-top: 16px
  }
  .cart-section .cart-item {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 16px;
  }
  .detail-content {
    margin-top: 16px;
  }
}

@media (max-width: 575px) {
  .cart-qty-row {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cart-desc-row {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .cart-qty-row {
    flex: 0 0 70%;
    max-width: 70%;
    margin-bottom: 16px;
  }
  .cart-desc-row {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cart-qty-row>.d-flex {
    justify-content: flex-start !important;
  }
  .cart-price-row {
    flex: 0 0 30%;
    max-width: 30%;
    text-align: right !important;
  }
}

/*====================================
SECTION & TITLE
======================================*/
section.about,
section.checkout-detail,
section.product-detail,
section.order-success {
  padding: 70px 0;
}
.about .section-title h3,
.checkout-detail .section-title .title,
.product-detail .section-title h3,
.order-success .section-title h3 {
  font-size: 30px !important;
  font-weight: bold;
}
.product .section-title {
  margin-bottom: 20px;
}
.product .section-title h3,
.product-detail .content .title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.about .section-title h3,
.product-detail .section-title h3 {
  margin-bottom: 40px;
}
.store .section-title h3 {
  text-align: left !important;
}

/*====================================
HOME
======================================*/
.promotion-title {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(221 221 221 / 0.6);
  display: block;
  width: 100%;
  padding: 20px;
  /* letter-spacing: 3px; */
}
.promotion-img {
  object-fit: cover;
  max-height: 600px;
  min-height: 300px;
  height: 100%;
}
.promotion-popup-img {
  object-fit: cover;
  height: 100%;
  max-height: 300px;
}
.campaign-popup-img {
  height: 100%;
  max-height: 300px;
  object-fit: contain;
  width: auto;
}
.menu-slider .item {
  padding: 0 !important;
}
.menu-title {
  color: #000 !important;
  font-weight: 700;
}
/* category */
.category-img {
  max-width: 120px;
  margin: auto;
  /* color: #ee2a24; */
}
.category-item {
  background-color: #f1f1f1 !important;
  /* color: red; */
}
/* menu */
.new-linup .box,
.best-seller .box {
  margin-bottom: 0 !important;
  height: 100%;
}
.new-linup .product-img img,
.best-seller .product-img img {
  margin-bottom: 16px;
  max-height: 140px;
  height: 100%;
}

.new-linup .product-excerpt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* ellipse text at n lines */
  -webkit-box-orient: vertical;
}

/* overwrite */
.new-linup .product-excerpt p,
.new-linup .product-excerpt span,
.best-seller .product-excerpt p,
.best-seller .product-excerpt span {
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 5px !important;
  font-size: 14px !important;
}

@media(max-width: 1855px) {
  .menu-slider .item .menu-title {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto
  }
}
@media(max-width: 1515px){
  .category-img {
    max-width: 110px;
  }
}
@media(max-width: 1325px) {
  .category-img {
    max-width: 90px;
  }
}
@media(max-width: 1216px) {
  .category-img {
    max-width: 70px;
  }
  .menu-slider .item .menu-title {
    bottom: 10px;
  }
}
@media(max-width: 1092px) {
  .menu-slider .item .menu-title {
    bottom: 0;
  }
}
@media(max-width: 999px) {
  .category-img {
    max-width: 110px;
  }
  .menu-slider .item .menu-title {
    bottom: 16px;
  }
}
@media(max-width: 599px) {
  .menu-slider .item .menu-title {
    bottom: 30px;
  }
}

/*====================================
PRODUCT
======================================*/
/* menu */
.product .section-view-all a {
  color: #959393;
}
.product .product-img img {
  max-width: 100%;
  margin-bottom: 16px;
  max-height: 140px;
  height: 100%;
  object-fit: contain;
}
.product .product-excerpt {
  color: #959393;
  font-size: 14px;
  margin-top: 5px;
}
.product .product-title a {
  font-weight: bold;
  color: #1a1919;
}
.product .product-price {
  color: #ee2a24;
  margin-top: 15px;
  font-size: 18px;
}
.product .product-excerpt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* ellipse text at n lines */
  -webkit-box-orient: vertical;
}
.store-field-padding .mat-form-field-infix {
  padding: .45em 0 .45em 0 !important;
  width: 220px !important;
}
/* overwrite */
.product-excerpt p,
.prod-toggle-desc p,
.product-excerpt span,
.prod-toggle-desc span  {
  font-family: 'Montserrat', sans-serif !important;
  margin-top: 5px !important;
}
.product-excerpt p,
.product-excerpt span {
  font-size: 14px !important;
}
.prod-toggle-desc p,
.ingredient p,
.prod-toggle-desc span,
.ingredient span {
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: #000 !important;
}
/* product detail */
.product-detail .packtype {
  text-transform: capitalize;
}
.toggle-content {
  margin-top: 16px;
  white-space: pre-wrap;
}
.border-bottom-line {
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 8px;
}
.option-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.cart-content .total,
.cart-content .quantity {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.new-linup .product-img img,
.best-seller .product-img img {
  max-width: 100%;
  object-fit: contain;
}
.product-img-wrap {
  border: 1px solid #fafafa;
  padding: 16px;
  border-radius: 22px;
  box-shadow: 0 7px 10px #fafafa;
}
.product-detail .item-quantity {
  border: 1px solid #dee2e6;
  padding: 8px;
  width: 50px;
}
section.home-menu {
  padding: 70px 0 !important;
}

/*====================================
PROFILE
======================================*/
.profile-img .thumb img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px  solid #a8a8a8;
  padding: 4px;
}
.profile-img .thumb {
  width: 100px;
}
.avatar-img {
  height: 28px;
  width: 28px;
  border-radius: 100%;
}

/*====================================
PURCHASE
======================================*/
.status-paid {
  color: #50ccb4;
}
.status-alert {
  color: #F2C222;
}
.box a {
  text-decoration: none !important;
}
.cust-content .box {
  margin-bottom: 0 !important;
  height: 100%;
}
.order-toggle.mat-button-toggle-group-appearance-standard {
  border-radius: 26px;
}
.order-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
}
.order-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 16px;
}
.order-toggle .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background: #ee2a24;
  color: #fff;
  font-weight: 600;
}

/* purchase-detail */
.item-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid #F2F2F2;
}
.item-remark {
  max-width: 80%;
  text-align: left;
}

@media(max-width:388px) {
  .pd-flex {
    flex-direction: column;
  }
  .pd-flex .text-right {
    text-align: left !important;
  }
}

/*====================================
VOUCHER
======================================*/
.voucher-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
}
.voucher-item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.validity-wrap {
  margin: 8px auto 0;
}
.validity {
  border: 2px solid;
  max-width: 100%;
  margin: 8px auto 0;
  text-transform: uppercase;
  padding: 4px;
}
.validity.valid {
  color: #29A005;
  border-color: #29A005;
}
.validity.expired {
  color: #F22222;
  border-color: #F22222;
}
.validity.utilized {
  color: #F2C222;
  border-color: #F2C222;
}
.voucher-name {
  font-weight: 600;
  font-size: 18px;
}
.voucher-desc p {
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
}

/*====================================
WALLET
======================================*/
.order-item {
  border-bottom: 1px solid #F2F2F2;
  padding: 16px 8px;
}
.order-item:hover {
  background: #fafafa;
}

/*====================================
PAYMENT
======================================*/
.btn-payoption {
  border-radius: 9px;
  width: 100%;
  color: #fff;
}
.partial-pay {
  background: #35B0F2;
}
.full-pay {
  background: #0B810F;
}

/*====================================
CHECKOUT
======================================*/
textarea.remark-textarea {
  border-color: #ebebeb;
  box-shadow: 0 7px 10px #fafafa;
}

.checkout-checkbox .mat-checkbox-inner-container,
.register-checkbox .mat-checkbox-inner-container {
  margin-top: 0 !important;
}

@media(max-width: 376px) {
  .detail-selection {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.payment-profile-img {
  border-radius: 100%;
  border: 1px solid #a8a8a8;
}
.payment-store {
  line-height: 20px;
  margin-top: 8px;
}

/*====================================
STORE & STORE DETAIL
======================================*/
.store .box {
  height: 100%;
  margin-bottom: 0 !important;
  padding: 30px 20px !important;
}
.icon-text {
  max-width: 50px;
  width: 100%;
  color: #ee2a24;
  text-align: center;
}
.icon-text i {
  width: 20px;
  height: 20px;
}
.store-address,
.store-phone,
.store-email {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.store-time {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
}
input[type=search].store-search {
  width: 50%;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

/* When the input field gets focus, change its width to 100% */
input[type=search]:focus.store-search {
  width: 100%;
  box-shadow: none;
}

@media(max-width: 430px) {
  input[type=search].store-search {
    width: 100%;
  }
}

/*====================================
LOGIN REGISTER
======================================*/
.show-password-icon {
  cursor: pointer;
  position: absolute;
  right: 20px;
}
.country-select .mat-autocomplete-panel {
  min-width: 280px;
}
.register-tab ul.nav.nav-tabs li:nth-child(1) a {
  border-radius: 32px 0 0 32px;
}
.register-tab ul.nav.nav-tabs li:nth-child(2) a {
  border-radius: 0 32px 32px 0 ;
}
.register-tab ul.nav.nav-tabs li a {
  border: 1px solid #dfe3e6 !important;
  box-shadow: 0px 0px 25px #A8AEC9CB;
  background-color: #fff !important;
  cursor: pointer;
}
.register-tab ul.nav.nav-tabs li a.active {
  background: #ee2a24 !important;
  color: #fff !important;
}
.form-forgot-password {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

/*====================================
TERMS
======================================*/
.pdfViewer .page {
  border: 1px solid #C1C1C1 !important;
}

/*====================================
NAVIGATION RESPONSIVE
======================================*/
/* .ordertext-mobile {
  display: none;
}
@media(max-width: 548px) {
  .order-online {
    display: block !important;
  }
  .ordertext-mobile {
    display: block;
  }
  .ordertext {
    display: none;
  }
} */

@media(max-width: 368px) {
  .username-text {
    font-size: 12px;
  }
}

/*====================================
HOMEPAGE RESPONSIVE
======================================*/
.menu-slider .item .menu-title {
  line-height: 14px;
}

@media (max-width: 1360px) {
  .menu-slider .item .menu-title {
    position: unset;
    display: block;
    margin-top: 10px;
  }
  .category-item {
    background-color: #fff !important;
  }
  .menu-slider .item {
    background-color: #fff !important;
    padding: 0;
  }
  /* .owl-stage-outer {
    min-height: 140px;
  } */
}
@media (max-width: 799px) {
  .category-img {
    max-width: 80px;
  }
  .owl-stage-outer {
    min-height: 100px;
  }
}

@media (max-width: 575px) {
  .title-row {
    margin-bottom: 8px;  
  }
}

@media (max-width: 391px) {
  .owl-stage-outer {
    min-height: 120px;
  }
}


/*====================================
MENU ITEM RESPONSIVE
======================================*/
.owl-nav::before {
  content: 'Scroll to view our menu categories' !important;
  display: block !important;
  padding-top: 8px;
  font-size: 14px;
}

@media (max-width: 493px) {
  .mat-form-field {
    width: 100%;
  }
  .sort-wrap {
    width: 100%;
  }
  .btn-add-to-cart {
    font-size: 14px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 390px) {
  .product .product-img img {
    max-height: 100px;
  }
}

/*====================================
TAB (LOGIN/REGISTER-TERM) RESPONSIVE
======================================*/
@media (max-width: 430px) {
  .register-tab ul.nav.nav-tabs li a {
    max-width: 120px;
  }
  .terms-tab ul.nav.nav-tabs li a {
    font-size: 12px !important;
    height: 100%;
  }
}

@media (max-width: 360px) {
  .terms-tab ul.nav.nav-tabs li a {
    padding: 8px 0 !important;
    border-radius: 32px !important;
    max-width: 140px;
  }
}

/* smart banners */
.section-header {
  position: sticky !important;
}
.page-content {
  margin-top: 0 !important;
}
.smartbanner-show .smartbanner {
  display: block;
  z-index: 100;
}
.smartbanner-show {
  top: 0 !important;
  margin-top: 80px !important;
}

/* dialogs */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.mat-dialog-container {
  position: relative;
}