
/*====================================
CUSTOM STYLES
======================================*/
/*@import url('https://fonts.googleapis.com/css?family=Poppins:300,500,700');
*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
a {
  /* color: #ee2a24; */
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #ee2a24;
  text-decoration: underline;
}

/* black - #1a1919*/
/*====================================
NAVBAR
======================================*/
#site-wrapper,
#main {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  /*  
  height: 5000px; *//* Temp: Simulates a tall page. */
}
#site-canvas,.site-wrapper{
  width: 100%;
  height: 100%;
  position: relative;
  
  -webkit-transform: translateX(0);
  transform: translateX(0);
  
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  
  -webkit-transition: 300ms ease all;
  transition: 300ms ease all;
  
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  
}
.show-nav #site-canvas,.show-nav .site-wrapper {
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
  
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0);
}
#site-menu, .site-menu{
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -300px;
  background: white;
  padding: 15px;
  box-shadow: 0px 0px 4px 0px #a2a2a2;
}
.site-menu .content li a {
  font-size: 24px;
  color: #000;
}

.site-menu .content {
  margin-top: 70px;
}

.site-menu .content li a:hover {
  color: #ee2a24;
  text-decoration: none;
}
/* hamburger menu */
.hamburger .line {
  width: 40px;
  height: 5px;
  background-color: #ecf0f1;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* NINE */

div#hamburger-9 {
  position: absolute;
  right: -70px;
  z-index: 99;
  top: 25px;
}
#hamburger-9{
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* #hamburger-9:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  border: 5px solid transparent;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active:before{
  border: 5px solid #ecf0f1;
} */

#hamburger-9.is-active .line{
  width: 35px;
}

#hamburger-9.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-9.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
}

#hamburger-9.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-13px) rotate(90deg);
}
/**/
nav.navbar {
  background-color: #1a1919;
}

.top-bar {
  background-color: #003654;
  color: #fff;
  padding: 2px 0;
}
.text-right.top-login {
  color: #fff;
}

.text-right.top-login a {
  color: #fff;
}
.top-left-menu {
  text-align: right;
}
.top-left-menu .t-menu {
  display: inline-block;
  margin-left: 10px;
}
.top-left-menu .t-menu a {
  color: #fff;
  font-size: 14px;
}

div#main-navbar ul li a {
  padding: 10px 30px;
  color: #fff;
  font-size: 18px;
}
div#main-navbar ul li.nav-item.active a,
div#main-navbar ul li.nav-item:hover a {
  color: #fa212a;
}
nav.navbar {
  padding: 15px 0;
}
nav.navbar .navbar-brand img {
  height: 60px;
}

/*====================================
HOMEPAGE
======================================*/
/*
Page title
*/
.page-title {
  margin: 70px 0;
  text-align: center;
}
.page-title h2{
  font-size: 30px;
  font-weight: bold;
}
/*
content
*/
section.page-content {
  padding: 50px 0 50px;
}

.site-content nav.navbar.navbar-expand-lg.navbar-dark .navbar-brand {
  margin: 0 auto;
}
/*  dim when sidebar menu active */
div#main.show-nav .site-content:after {
  content: "";
  height: 100%;
  background-color: rgb(0 0 0 / 0.6);
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  position: absolute;
  transition: 0.3s;
}

.site-content {
  position: relative;
}
/* section promotion banner */
section.promotion-banner{
  padding: 50px 0 0;
}
.promotion-banner .section-title {
  margin-bottom: 20px;
}
.promotion-banner .section-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.promotion-banner .section-view-all a {
  color: #959393;
}
.promotion-banner .item {
  position: relative;
}

.promotion-banner .item .promotion-title {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(221 221 221 / 0.6);
  display: block;
  width: 100%;
  padding: 20px;
  /* letter-spacing: 3px; */
}
/* section home-menu */
section.home-menu{
  background-color: white;
  padding: 50px 0;
}
.home-menu .section-title {
  margin-bottom: 20px;
}
.home-menu .section-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.home-menu .section-view-all a {
  color: #959393;
}
.menu-slider .item {
  border-radius: 50%;
  background-color: #000;
  padding: 15px;
}
.menu-slider .item a:hover {
  text-decoration: none;
}
.menu-slider .item img {
  object-fit: cover;
  width: 100%;
}
.menu-slider .item .menu-title {
  color: white;
  text-align: center;
  font-size: 14px;
}

/* section line up */
section.new-linup {
  background-color: #f4f8fb;
  padding: 50px 0;
}
.new-linup .section-title {
  margin-bottom: 20px;
}
.new-linup .section-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.new-linup .section-view-all a {
  color: #959393;
}
.new-linup .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  padding: 20px 15px;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
}
.new-linup .product-img img {
  max-width: 100%;
}
.new-linup .product-excerpt {
  color: #959393;
  font-size: 14px;
  margin-top: 5px;
}
.new-linup .product-title a {
  font-weight: bold;
  color: #1a1919;
}

.new-linup .product-price {
  color: #ee2a24;
  margin-top: 15px;
  font-size: 18px;
}

/* section best seller */
section.best-seller {
  background-color: #ffffff;
  padding: 50px 0 70px;
}
.best-seller .section-title {
  margin-bottom: 20px;
}
.best-seller .section-title h3 {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
.best-seller .section-view-all a {
  color: #959393;
}
.best-seller .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  padding: 20px 15px;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
}
.best-seller .product-img img {
  max-width: 100%;
}
.best-seller .product-excerpt {
  color: #959393;
  font-size: 14px;
  margin-top: 5px;
}
.best-seller .product-title a {
  font-weight: bold;
  color: #1a1919;
}

.best-seller .product-price {
  color: #ee2a24;
  margin-top: 15px;
  font-size: 18px;
}
/* register form */

.register-tab .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.register-tab ul.nav.nav-tabs li a {
  border: 3px solid #1a1919;
  font-weight: 700;
  background-color: #1a1919;
  font-size: 18px;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: #8a8a8a;
  transition: 0.3s;
  display: block;
}
.register-tab ul.nav.nav-tabs li a.active {
  border: 3px solid #1a1919;
  font-weight: 700;
  background-color: transparent;
  font-size: 18px;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: #ee2a24;
  transition: 0.3s;
  display: block;
}
.register-tab ul.nav.nav-tabs li a:hover,
.register-tab ul.nav.nav-tabs li a:active{
  text-decoration: none;
}

/* .form-register-mobile input[type="text"],
.form-register-mobile input[type="email"],
.form-register-mobile input[type="password"],
.form-register-email input[type="text"],
.form-register-email input[type="email"],
.form-register-email input[type="password"] {
  padding: 15px 15px;
  border: 3px solid #1a1919;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  height: 50px;
  border-radius: 0;
} */
.form-register-mobile,
.form-register-email {
  width: 100%;
  max-width: 800px;
  margin: 50px auto 0;
}
button.btn.btn-submit {
  border: 3px solid #1a1919;
  font-weight: 700;
  background-color: #1a1919;
  font-size: 18px;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  border-radius: 0;
}
button.btn.btn-submit:hover {
  background-color: #fff;
  color: #1a1919;
  transition: 0.3s;
}
.form-group.c-left {
  width: 75px;
  float: left;
}

.form-group.c-right {
  width: calc( 100% - 85px);
  float: left;
  margin-left: 10px;
}
.form-group.submitform {
  margin-top: 20px;
  text-align: center;
}
/* .form-login-mobile input[type="text"],
.form-login-mobile input[type="email"],
.form-login-mobile input[type="password"],
.form-login-email input[type="text"],
.form-login-email input[type="email"],
.form-login-email input[type="password"] {
  padding: 15px 15px;
  border: 3px solid #1a1919;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  height: 50px;
  border-radius: 0;
} */
.form-login-mobile,
.form-login-email {
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;
}
/*====================================
PROMOTION
======================================*/
section.promotion {
  padding: 70px 0;
}
.promotion .section-title h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
.promotion .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  height: 320px;
  position: relative;
}

.promotion .box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.promotion .promotion-title {
  position: absolute;
  bottom: 0;
  background-color: rgb(221 221 221 / 0.7);
  display: block;
  width: 100%;
  padding: 15px;
  color: #000;
  font-weight: bold;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
/* Promotion Detail */
section.promotion-detail {
  padding: 70px 0;
}
.promotion-detail .section-title h3 {
  font-size: 30px;
  font-weight: bold;
  
  margin-bottom: 40px;
}
.promotion-detail .content .title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.promotion-detail .content .date {
  font-weight: bold;
  color: #000;
}

.promotion-detail .content .line {
  border-bottom: 1px solid #ddd;
  margin: 10px 0 20px;
}

.promotion-detail .content a.btn-voucher {
  background-color: #000;
  padding: 5px 50px;
  border-radius: 35px;
  color: #fff;
  font-weight: bold;
  border: 3px solid #000;
  display: inline-grid;
  transition: 0.3s;
  font-size: 20px;
  text-align: center;
}

.promotion-detail .content a.btn-voucher:hover {
  text-decoration: none;
  background-color: #fff;
  color: #000;
  transition: 0.3s;
}

.promotion-detail .content .voucher-code {
  margin-top: 40px;
}

.promotion-detail .content a.btn-voucher small {font-size: 10px;}

.promotion-detail .thumbnail img {
  /* height: 400px;
  width: 100%; */
  object-fit: cover;
}
.promotion-detail .thumbnail {
  margin-bottom: 30px;
}
/*====================================
STORE
======================================*/
section.store {
  padding: 70px 0;
}
.store .section-title h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}
.store .selected-store span {
  font-weight: bold;
}

.store .selected-store {
  margin-bottom: 10px;
}

.store .selected-store .fas {
  margin-right: 8px;
  font-size: 20px;
}
.store .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  padding: 20px 20px 30px;
  position: relative;
}
.store .box .top .name {
  float: left;
  font-size: 16px;
  font-weight: bold;
}
.store .box .top .range {
  float: right;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 5px;
}
.store .box span {
  /* width: calc(100% - 45px); */
  display: inline-flex;
}
.store .box .address,
.store .box .phone,
.store .box .email {
  font-size: 14px;
  margin-top: 15px;
}

.store .box .address .fas,
.store .box .phone .fas,
.store .box .email .far {
  margin-right: 10px;
  height: 35px;
  width: 35px;
  background-color: #f2f2f2;
  border-radius: 50%;
  position: relative;
  padding: 14px;
  text-align: center;
  top: 10px;
  color: #ee2a24;
}
.store .box .address .fas:before{
  position: relative;
  top: -6px;
  right: 0;
  left: -8px;
  font-size: 19px;
}
.store .box .phone .fas:before {
  position: relative;
  top: -6px;
  left: -2px;
  font-size: 20px;
}
.store .box .email .far:before{
  position: relative;
  top: -6px;
  left: -6px;
  font-size: 19px;
}
.store .box .btn-select a {
  /* background-color: #000; */
  /* color: #fff; */
  /* width: 200px; */
  /* display: block; */
  text-align: center;
  /* padding: 9px; */
  border-radius: 30px;
  /* margin: 0 auto; */
  /* transition: 0.3s; */
}
.store .box .btn-select a:hover {
  text-decoration: none;
  /* background-color: #ee2a24; */
  /* transition: 0.3s; */
}
.store .box .btn-select {
  margin-top: 25px;
}
/*====================================
CUSTOMER DASHBOARD
======================================*/
section.customer-dashboard {
  padding: 70px 0;
}
.sidebar .profile .thumb img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px  solid #a8a8a8;
  padding: 4px;
}

.sidebar .profile .thumb {
  width: 100px;
  float: left;
}

.sidebar .profile .details {
  float: left;
  width: calc( 100% - 100px);
  padding: 15px;
}
.sidebar .profile .details .name {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}
.sidebar ul.list-unstyled.cust-menu {
  margin-top: 40px;
}
.sidebar ul.list-unstyled.cust-menu li a {
  color: #000;
  font-weight: bold;
}

.sidebar ul.list-unstyled.cust-menu li.active a, 
.sidebar ul.list-unstyled.cust-menu li a:hover {
  color: #ee2a24;
  text-decoration: none;
  transition: 0.2s;
}
ul.list-unstyled.cust-menu li{
  margin-bottom:5px;
}
/*  dashboard content */
.cust-content .content-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
.cust-content .box {
  box-shadow: 0px 0px 25px #dfe3e6;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}
table.table.table-orderlist tr th {
  padding: 0 0 10px 0;
  color:  #ee2a24;
  border: 0;
}

table.table.table-orderlist tr td {
  padding: 0;
  border: 0;
}

table.table.table-orderlist tr td:last-child {
  text-align: right;
  font-weight: bold;
}

table.table.table-orderlist tr:last-child td {
  color: #ee2a24;
  font-weight: bold;
}

/*====================================
FOOTER
======================================*/
body{
  font-family: 'Montserrat', sans-serif;
}
.footer-top{
  background-color: #596b5f;
  padding: 60px 0;
}
.footer-top img {
  width: 100px;
}
.footer-top h4 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 25px;
  margin-top: 25px;
}
.footer-top ul.social-icon {
  list-style: none;
  padding-left: 0;
  display: inline-flex;
  margin-top: 21px;
}
.footer-top ul.social-icon li {
  margin-right: 8px;
}
.footer-top ul.social-icon li img {
  width: 45px;
  height: auto;
}
.footer-top .address {
  font-size: 16px;
  color: #fff;
  margin-top: 20px;
  font-weight: 100;
}
.footer-top .email,
.footer-top .phone{
  position: relative;
}
.footer-top .phone:before{
  content: '';
  /* background-image: url('assets/images/icon-time.png'); */
  height: 23px;
  width: 23px;
  position: absolute;
  left: 0;
  top: 3px;
  background-size: cover;
}
.footer-top .email:before{
  content: '';
  /* background-image: url('assets/images/icon-envelope.png'); */
  height: 17px;
  width: 23px;
  position: absolute;
  left: 0;
  top: 5px;
  background-size: cover;
}
.footer-top .email a,
.footer-top .phone a {
  color: #fff;
  margin-left: 30px;
}
.footer-top .time,
.footer-top .enquiry {
  color: #fff;
  margin-left: 30px;
  margin-bottom: 25px;
  font-weight: 100;
}
.footer-top ul.link {
  list-style: none;
  padding-left: 0;
}
.footer-top ul.link li a {
  color: #fff;
  font-weight: 100;
}
.footer-top ul.link li {
  margin-bottom: 8px;
}
.footer-top .date {
  color: #fff;
  font-weight: 100;
}
.footer-top .title a{
  color: #fff;
}
.footer-top .title{
  margin-bottom: 25px;
}

.footer-copyright.text-center {
  padding: 25px 0;
  font-size: 14px;
  background-color: #1a1919;
  color: #fff;
}
.footer-copyright.text-center a {
  color: #f6242c;
}

/*+++++++++++++++++++++++++++++++++
RESPONSIVE
+++++++++++++++++++++++++++++++++*/
@media (min-height: 800px){
  .site-content.pg-login,
  .site-content.pg-promotion-detail {
    height: calc(100vh - 71px);
  }
}
@media (min-width: 1366px){
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 1024px){
  .welcome .left img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
  }
}

@media (max-width: 768px){
  
}

@media (max-width: 575px){
  .about-top ul.about-menu li {
    display: block;
    padding: 8px 8px;
    border-bottom: 2px solid #fff;
  }
}